
/**
 * basic table
 */
 table.simple {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table.simple thead th  {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

table.simple th {
  padding: .5em;
}

table.simple:not(.dense) td:not(.icon) {
  padding: .5rem;
}
table.simple td {
  border-top: 1px solid #dee2e6;
}

table.simple.table-hover tr:not(.no-hover):not(.selected):hover {
  background: #f5f5f5;
}

// no hover on headers
table.simple.table-hover thead:hover {
  background: none;
}


table.simple tr.selected:hover,
table.simple tr.selected,
table.simple tr.selected a {
  background: $primary-color;
  color: #fff;
}

table.dense th,
table.dense td {
  font-size: .9em;
  padding: 0.3rem;
}
