/**
* material overrides
*/

/* only do uppercases on custom Subtitle component for now */
.MuiTypography-root.title {
  text-transform: uppercase;
}

div.MuiToolbar-dense {
  min-height: 30px ;
}

.MuiTabs-root {
  background: #fff !important;
}

.MuiTab-root {
  text-transform: none !important;
  min-width: inherit !important;
}

.MuiButtonBase-root.Mui-selected {
  color: #333;
}

.MuiTabs-indicator {
  background-color: $primary-color !important; /* primary color */
  transform: translateY(-45px);
}

.MuiTabs-indicator {
  transition: none;
}

.MuiCircularProgress-indeterminate {
  animation-duration: 0.8s !important;
}

.MuiCard-root,
.MuiPaper-root:not(.MuiAppBar-root) {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.MuiPaper-elevation1 {
  /* Fixme(nc): hack to make paper above selector label hack */
  z-index: 2 !important;
}

.MuiTabs-root[orientation="vertical"] .MuiTabs-flexContainer {
  /* Fixme(nc): hack to fix vertical tab bug */
  flex-direction: column;
}

.MuiTabs-root[orientation="vertical"] .MuiTabs-indicator {
  display: none;
}

.MuiButton-root,
.MuiToggleButton-label {
  text-transform: none;
}

.MuiStep-root {
  display: inline-flex;
  margin-bottom: 20px;
}
.MuiStepLabel-label {
  margin-left: 10px;
  font-size: 1em;
}

.MuiTableCell-stickyHeader {
  background-color: #fff;
}

.MuiChip-outlined {
  color: #333;
}

a.MuiChip-colorPrimary,
a.MuiListItem-root {
  color: initial;
}

.no-elevation {
  box-shadow: none !important;
}


