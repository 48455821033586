
/**
* handsontable overrides
*/

.handsontable th {
  font-weight: 500;
}

.handsontable .htDimmed,
.handsontable td {
  color: #333;
}
