$spacing: 8px;

.not-found{
  background-color: rgb(14, 51, 83);
  background-image: url('../../assets/imgs/header-bg2.png');
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-repeat: repeat;
  background-position: 10% 50%;
  background-size: cover;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-size: 10em;
  font-weight: 800;
}

.not-found small {
  display: block;
  font-size: 18px;
  color: rgb(14, 51, 83);
  -webkit-text-fill-color: rgb(14, 51, 83);;
  text-transform: none;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
  float: left;
  width: 100%;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: .875rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.175);
}

.green-icon {
  color: #76a72d;
}

.action-drawer-container {
  position: relative;
}

.action-drawer {
  display: inline-block;
  z-index: 9999;
  margin-top: 50px;
  top: 0px;
  right: 0px;
  height: 600px;
  width: 60px;
  background: #76a72d;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
}

.muted { color: #666; }
.italic { font-style: italic; }
.hover { opacity: .7; }
.hover:hover { cursor: pointer; opacity: 1.0;  }

.pull-right { float: right; }
.pull-left { float: left; }

/**
 * alerts
 */
.alert {
  padding: 20px;
  border-width: 1px;
  line-height: 1.5em;

  &.alert-fail {
    color: #8d1111;
    background: #fae2e4;
    border-color: #f5c6cb;
  }
  &.alert-warning {
    color: #765906;;
    background: #fff3cd;
    border-color: #ffeeba;
  }
}

/**
 * floating (useful when grid is overkill)
 */

/**
 * general colors
 */
.queued { color: #777; }
.in-progress { color: #ffa900; }
.completed, .success { color: #4aa94b; }
.failed { color: #d80400; }


/**
 * card related stuff
 */

 .card {
  position: relative;
  margin: 1*$spacing 1*$spacing;
  padding: 1*$spacing 2*$spacing;
}

.card-progress {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

