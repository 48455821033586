$primary-color: #2e75a3;

body {
    font-family: 'Roboto';
    font-size: 14px;
}

p {
    line-height: 1.5em;
}

a:not(.no-style), a.visited {
    color: #3a8cc2;
    text-decoration: none;
}
a:hover {
    cursor: pointer;
    color: #215b81;
    text-decoration: underline;
}

a[role="button"]:hover, a[role="tab"]:hover {
    text-decoration: none;
}

a.no-style {
    color: inherit;
    text-decoration: none;
}

.p4-ui {
    background-color: #efefef;

    @import './nav-bar.scss';
    @import './general.scss';
    @import './layout.scss';
    @import './tables.scss';
    @import './mui-overrides.scss';
    @import './hottable-overrides.scss';
}
