
.flex {
  display: flex;
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex: 1;
}
